<template>
  <div class="info_container">
    <el-row class="info_header">
      <el-col :span="4">{{ name }}</el-col>
      <el-col :offset="4" :span="16" class="step">
        1.选择科室&nbsp;&nbsp; 2.选择医生&nbsp;&nbsp;
        <span class="active">3.选择时段</span>&nbsp;&nbsp;4.确认挂号
      </el-col>
    </el-row>
    <Count></Count>
    <div class="info_main">
      <div class="info">
        <img alt="" src="../../assets/doc.png" />
        <div class="content">
          <p>
            医生姓名：
            <span>{{ YSMC || doctorList[0].MC }}</span>
          </p>
        </div>
        <div class="introduction">
          <p class="intro_title">简介:</p>
          <p class="intro_content">{{ query.JJ }}</p>
        </div>
      </div>
      <div class="tipInfo">
        <h1>请选择点击下方的班次时段进行挂号</h1>
      </div>
      <div class="time">
        <el-row class="time_title">
          <div class="title-item">
            当前位置：号源列表
          </div>
          <div class="title-item">
            姓名：<span>{{ patName }}</span>

            就诊卡号：<span>{{ patCardNo }}</span>
          </div>
        </el-row>
        <div class="itemBox">
          <div v-for="(item, i) in doctorList" :key="i" class="item" @click="order(item)">
            <p>{{ item.SJFW || item.KZSJ }}</p>
            <p>余号：{{ item.HY }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-row class="info_tip">
      <el-col :span="24">
        <span>{{ patName }}</span
        >&nbsp;&nbsp;您好&nbsp;&nbsp;欢迎使用自助终端，请您使用结束后退出首页&nbsp;&nbsp;如非本人，请退出首页，谢谢！
      </el-col>
    </el-row>
    <el-button class="back" type="primary" @click.native="back">返回</el-button>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import qs from 'qs'
import { jsonPost, formPost } from '@/baseAPI'
import { getSchedulingList, getAppointmentRegList } from '@/service/register'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      YSBM: '',
      name: '',
      YSMC: '',
      patName: '',
      timeList: [],
      JSRQ: '',
      deptCode: '',
      KSRQ: '',
      doctorList: [{ doctorName: '' }],
      query: {}
    }
  },
  created() {
    this.query = this.$route.query
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.YSBM = this.$route.query.YSBM
    this.KSRQ = this.$route.query.KSRQ
    this.YSMC = this.$route.query.YSMC
    this.JSRQ = this.$route.query.JSRQ
    this.doctorCode = this.$route.query.doctorCode
    this.patCardNo = this.$route.query.patCardNo
    this.getRegList()
  },
  methods: {
    //获取号源
    async getRegList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.name == '当天挂号') {
        let data = {
          YSBM: this.YSBM
        }
        const res = await getSchedulingList(data)
        console.log(res)
        loading.close()
        if (res.data.code == 0) {
          let item = []
          item.push(res.data.data.DATASET.ROW)
          this.doctorList = res.data.data.DATASET.ROW
        } else {
          loading.close()
          this.doctorList = []
          this.$message({
            message: '暂无医生信息',
            type: 'warning'
          })
        }
      } else {
        let data = {
          YSBM: this.$route.query.BM,
          KSRQ: this.KSRQ,
          JSRQ: this.JSRQ
        }
        const res = await getAppointmentRegList(data)
        console.log(res)
        loading.close()
        if (res.data.code == 0) {
          let item = []
          item.push(res.data.data.DATASET.ROW)
          this.doctorList = res.data.data.DATASET.ROW
        } else {
          loading.close()
          this.doctorList = []
          this.$message({
            message: '暂无医生信息',
            type: 'warning'
          })
        }
      }
    },
    back() {
      this.$router.back()
    },
    home() {
      this.$router.push('/home')
    },
    order(item) {
      this.$router.push({
        path: '/order',
        query: {
          name: this.name,
          patName: this.patName,
          YSMC: this.YSMC,
          YSBM: this.YSBM,
          RQ: item.SJFW,
          KZSJ: item.KZSJ,
          YSBMs: item.YSBM,
          SJD: item.SJD,
          MC: item.MC,
          BM: this.BM,
          KSBMs: item.KSBM,
          YYRQ: this.$route.query.YYRQ,
          KSBM: this.$route.query.KSBM,
          cardNo: this.$route.query.cardNo,
          patCardNo: this.$route.query.patCardNo,
          patCardType: this.$route.query.patCardType
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.info_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.info_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.info_header .step {
  font-size: 35px;
  font-weight: 400;
  padding: 0;
  letter-spacing: 0;
  text-align: right;
  padding-right: 30px;
}

.info_header .step .active {
  color: blue;
}

.info_title {
  margin-top: 10%;
}

.info_title h1 {
  margin: 10px 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
  letter-spacing: 10px;
  font-size: 44px;
}

.info_main {
  width: 85%;
  height: 75%;
  margin: 0 auto;
}

.info_main .info {
  overflow: hidden;
  /* height: 20%;
  width: 100%; */
}

.info_main .info img {
  margin-top: 70px;
  float: left;
  height: 50%;
  width: 15%;
}

.info_main .info .content {
  float: left;
  height: 80%;
  width: 85%;
  color: #fff;
  font-size: 40px;
  padding-left: 30px;
}

.info_main .info .content p {
  margin-top: 20px;
  font-size: 50px;
  font-weight: 700;
}

.info_main .info .content p span {
  color: #fff;
}

.introduction {
  height: 160px;
  margin-top: 20px;
  font-size: 40px;
  font-weight: bold;
  color: white;
}

.info_main .tipInfo h1 {
  margin-top: 2%;
  margin-bottom: 45px;
  text-align: left;
  font-size: 48px;
  color: red;
}

.time {
  max-height: 60%;
  overflow: auto;
  background-color: #fff;
}

.time .itemBox {
  padding: 30px 30px;
}

.time .time_title {
  background-color: #409eff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time .title-item {
  color: #fff;
  font-size: 42px;
}

.title-item span {
  font-weight: 800;
}

.time .item {
  display: inline-block;
  width: 300px;
  height: 150px;
  color: white;
  font-size: 40px;
  background-color: #409eff;
  border-radius: 6px;
  margin: 10px 20px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 60px;
}

.info_tip {
  position: fixed !important;
  bottom: 40px;
  left: 0;
  z-index: 1;
  padding-left: 30px;
  color: red;
  font-size: 20px;
}

.info_tip span {
  color: blue;
}

.info_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 35px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 35px !important;
}
</style>
